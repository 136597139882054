<template>
  <div
    v-if="$store.state.account.loginStatus"
    class="menu-logo menu-logo-cursor"
    @click="$router.push('/')"
    @keydown="$router.push('/')"
  >
    <div>控制台</div>
  </div>
  <div
    v-else
    class="menu-logo"
  >
    <div>控制台</div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MenuLogo',
});
</script>

<style lang="scss" scoped>
.menu-logo {
  float: left;
  height: 31px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  user-select: none;
}

.menu-logo-cursor {
  cursor: pointer;
}
</style>
