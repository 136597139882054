import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { VueCookieNext } from 'vue-cookie-next';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import * as globalFunctions from './utilities/globalFunctions';

const app = createApp(App);
app.config.globalProperties.$globalFunctions = globalFunctions;
app.use(store).use(router).use(Antd).use(VueCookieNext)
  .mount('#app');
